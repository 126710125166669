import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../../organisms/DefaultLayout";
import * as styles from "../../styles/company/ceo-message.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { TitleCover } from "../../atoms/title-cover/TitleCover";
import SignOne from "../../images/ceomessage/ceo_sign01.png";
import SignTwo from "../../images/ceomessage/ceo_sign02.png";
import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/companyoverview/_kpo072_re8.jpg";

export default ({ location: pathname }) => {
  const [isWhite, setIsWhite] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsWhite(true);
      } else {
        setIsWhite(false);
      }
    });

    const targets = document.querySelectorAll(
      "[data-change-header-color-point]"
    );
    targets.forEach(target => {
      observer.observe(target);
    });
  }, []);

  return (
    <DefaultLayout isWhite={isWhite}>
      <SEO
        pathname={pathname}
        title="代表メッセージ | CEO Message"
        description="私たちは、「この上ない最高の世界を創る」というビジョンを掲げ、多角化事業である「コングロマリット経営」を強みとして発展してまいりました。2005年の創業以来拡大を続けることができているのは、紛れもなく我々に関わっていただいたお客様、そして共に働く仲間の存在があってこそ。この機会に改めて日頃の感謝を申し上げます。引き続き、「顧客」「社会」「従業員」に対して、「この上ない最高」を提供する会社を目指し、あらゆる事業を永続性と拡大性を兼ね備え、新たなステージへと全社一丸で挑戦してまいります。"
        image={seoImage}
      />
      <div className={styles.absolute}>
        <TitleCover
          background={
            <StaticImage
              src="../../images/ceomessage/p7170797_rere_ceom.jpg"
              style={{ width: "100%", height: "100%" }}
              objectPosition="right bottom"
              objectFit="cover"
              alt="代表メッセージ"
            />
          }
          title="CEO Message"
          sub="代表メッセージ"
        />
      </div>

      {/* Intersection Observer 用にヘッダーのカラーを変えるポイントを仕込む */}
      <div data-change-header-color-point="data-change-header-color-point" />

      <div className={styles.friendContainer}>
        <p className={styles.friend}>
          私たちは、「この上ない最高の世界を創る」というビジョンを掲げ、多角化事業である「コングロマリット経営」を強みとして発展してまいりました。
          <br />
          <br />
          2005年の創業以来拡大を続けることができているのは、
          <br />
          紛れもなく我々に関わっていただいたお客様、そして共に働く仲間の存在があってこそ。
          <br />
          この機会に改めて日頃の感謝を申し上げます。
          <br />
          <br />
          引き続き、「顧客」「社会」「従業員」に対して、「この上ない最高」を提供する会社を目指し、あらゆる事業を永続性と拡大性を兼ね備え、新たなステージへと全社一丸で挑戦してまいります。
          <br />
          <br />
          <br />
        </p>
        <div className={styles.signContainer}>
          <div>
            <img className={styles.signOne} src={SignOne} alt="" />
          </div>
          <div>
            <img className={styles.signTwo} src={SignTwo} alt="" />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
