import React, { ReactNode } from "react";
import * as styles from "./TitleCover.module.scss";
import { compact } from "lodash";

interface TitleCoverProps {
  background: ReactNode;
  title: string;
  sub: string;
  className?: string;
  bgClassName?: string;
}
export const TitleCover = ({
  background,
  title,
  sub,
  className,
  bgClassName,
}: TitleCoverProps) => {
  return (
    <div className={compact([styles.container, className]).join(" ")}>
      <div className={compact([styles.bg, bgClassName]).join(" ")}>
        {background}
      </div>
      <div className={styles.titleWrap}>
        {/* <div className="flex-1"/> */}

        <h2>{title}</h2>
        <h3>{sub}</h3>

        {/* <div className="flex-1"/> */}
      </div>
    </div>
  );
};
